import React, { createContext, useState, useEffect } from 'react'

const defaultLocale = 'en'
const supportedLocales = ['fr', 'en']

export const Locale = createContext({
	locale: defaultLocale,
	setLocale: () => {},
})

export const LocaleProvider = ({ children }) => {
	const [locale, setLocale] = useState(defaultLocale)

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const pathLocale = window.location.pathname.split('/')[1]
			if (supportedLocales.includes(pathLocale)) {
				setLocale(pathLocale)
			} else {
				const browserLocale = window.navigator.language.split('-')[0]
				setLocale(supportedLocales.includes(browserLocale) ? browserLocale : defaultLocale)
			}
		}
	}, [])

	return (
		<Locale.Provider value={{ locale, setLocale }}>
			{children}
		</Locale.Provider>
	)
}